<app-loader *ngIf="loaderStatus"></app-loader>

<div class="col-xxl-12 col-xl-12 col-lg-12 d-grid gap-4 p-3 bg-white rounded-3">
  <div class="d-grid gap-3">
    <div class="d-flex justify-content-center">
      <div class="user-round">
        <img [src]="avatarUrl" class="user-round img-user-avatar" alt="profile" *ngIf="
            avatarUrl && avatarUrl.length > 0;
            else placeholder
          " />
        <ng-template #placeholder>
          <div class="user-round">
            <img src="assets/svg/user-avatar.svg" alt="Avatar" class="user-round" />
            <!-- <h4>{{ user?.userName?.charAt(0)?.toString()?.toUpperCase() }}</h4> -->
          </div>
        </ng-template>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="d-flex align-items-center border-0 gap-2 p-2 btn-avatar"
        (click)="fileUpload.fileUpload.showFileSelector()">
        <img src="assets/svg/upload 1.svg" alt="upload avatar" />
        Thay ảnh đại diện
        <app-file-upload hidden #fileUpload [files]="avatar" [multipleFiles]="false"
           [description]="'Định dạng PNG, JPEG dung lượng tối đa 1MB'"
          (select)="onSelect($event)" (remove)="onRemove($event)"></app-file-upload>
      </button>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" style="font-size: 11px">
      <div>Dung lượng file tối đa 1 MB.</div>
      <div>Định dạng: JPEG, PNG</div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center gap-4 fs-6">
    Trạng thái tài khoản:
    <span class="d-flex align-items-center gap-1">
      <div>
        <span style="color: #247636">{{ status }}</span>
      </div>
      <!-- <div *ngIf=" status == 'Không Hoạt Động'">
        <span style="color: #be050e">{{ status }}</span>
      </div>
      <div *ngIf=" status == 'Chờ Xét Duyệt'">
        <span style="color: #a7a704">{{ status }}</span>
      </div> -->
    </span>
  </div>
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex justify-content-center align-items-center" *ngIf="user">
      <div class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8">
        <div class="d-grid gap-3">
          <div>
            Họ và tên <span style="color: #dd0000">*</span>
            <input #fullName type="text" name="fullName" formControlName="fullName" class="form-control profile-input"
              placeholder="Họ và tên" required />
            <label *ngIf="form.controls['fullName'].touched && form.controls['fullName'].errors?.['required']"
              class="invalid-feedback">
              Nhập Họ và tên
            </label>
          </div>
          <div>
            Ngày sinh
            <div class="d-flex gap-4">
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-sm-3">
                <select class="form-select profile-input" name="day" aria-label="Ngày" (change)="changeDay($event)">
                  <option hidden>Ngày</option>
                  <option *ngFor="let d of days" [selected]="day == d.getDate()" [value]="d">
                    {{ d.getDate() }}
                  </option>
                </select>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-sm-3">
                <select class="form-select profile-input" name="month" aria-label="Tháng"
                  (change)="changeMonth($event)">
                  <option hidden>Tháng</option>
                  <option *ngFor="let m of months" [selected]="month == m" [value]="m">
                    {{ m }}
                  </option>
                </select>
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-sm-3">
                <select class="form-select profile-input" name="year" aria-label="Năm" (change)="changeYear($event)">
                  <option hidden>Năm</option>
                  <option *ngFor="let y of years" [selected]="year == y" [value]="y">
                    {{ y }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            Giới tính
            <div class="d-flex gap-4">
              <div class="form-check div-radio">
                <input class="form-check-input profile-radio" type="radio" name="rdGender" id="rdMale" [value]="1"
                  [checked]="user.gender == 1" (change)="selectGender(1)" />
                <label class="form-check-label" for="rdMale"> Nam </label>
              </div>
              <div class="form-check div-radio">
                <input class="form-check-input profile-radio" type="radio" name="rdGender" id="rdFemale" [value]="0"
                  [checked]="user.gender == 0" (change)="selectGender(0)" />
                <label class="form-check-label" for="rdFemale"> Nữ </label>
              </div>
              <div class="form-check div-radio">
                <input class="form-check-input profile-radio" type="radio" name="rdGender" id="rdOther" [value]="2"
                  [checked]="user.gender == 2" (change)="selectGender(2)" />
                <label class="form-check-label" for="rdOther"> Khác </label>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex gap-2 align-items-center">
              <div>
                Số điện thoại
                <span style="color: #dd0000">*</span>
              </div>
              <span id="popover" class="d-flex position-relative w-50">
                <img class="profile-icon" src="assets/svg/info 1.svg" alt="info" />
                <span class="popover d-none p-2 position-absolute">Số điện thoại cuả bạn là duy nhất và không thể thay
                  đổi</span>
              </span>
            </div>
            <input #phone type="tel" name="phone" formControlName="phone" class="form-control profile-input"
              placeholder="Số điện thoại" readonly required />
            <label *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['required']"
              class="invalid-feedback">
              Nhập Số điện thoại
            </label>
          </div>
          <div>
            Email
            <input #email name="email" formControlName="email" class="form-control profile-input"
              placeholder="Hãy nhập email để nhận được thông tin ưu đãi mỗi ngày" />
          </div>
        </div>
        <div class="d-flex justify-content-center pt-5">
          <div class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8">
            <button class="btn save-button">Lưu thay đổi</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>