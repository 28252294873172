// import { RePayment } from './../action/order.action';
import { PaginateModel } from "./core.interface";
// import { Coupon } from "./coupon.interface";
// import { OrderStatus } from "./order-status.interface";
import { Product } from "./product.interface";
// import { Stores } from "./store.interface";
import { User, UserAddress } from "./user.interface";
import { Voucher } from "./voucher.interface";
// import { TransactionsData } from "./wallet.interface";

export interface OrderModel extends PaginateModel {
    orderHistory: Order[];
    total: number;
    userName: string;
}

export interface Order {
    id?: string;
    items: OrderItem[];
    orderId: string;
    globalOrderId?: string;
    sumTotalPrice?: number;
    finalPrice: number;
    orderStatus: OrderStatus;
    orderDate?: string;
    receiverInfo: ReceiverInfo;
    appliedVoucher?: Voucher;
    voucherDiscountAmount?: number;
    note?: string;
    paymentMethod?: string;
}

export interface OrderItem {
    imgUrl: string;
    name: string;
    price: number;
    productID: number;
    quantity: number;
    totalPrice: number;
}

export interface ReceiverInfo {
    address: string;
    phone: string;
    userName: string;
    email: string;
}

export interface OrderCheckout {
    total: CheckoutTotal;
}

export interface CheckoutTotal {
    convert_point_amount: number;
    convert_wallet_balance: number;
    coupon_total_discount: number;
    points: number;
    points_amount: number;
    shipping_total: number;
    sub_total: number;
    tax_total: number;
    total: number;
    wallet_balance: number;
}

export interface CheckoutPayload {
    consumer_id: number;
    products: OrderProduct[];
    shipping_address_id: number;
    billing_address_id: number;
    coupon?: string;
    points_amount?: boolean;
    wallet_balance?: boolean;
    delivery_description?: string;
    delivery_interval?: string;
    payment_method?: string;
}


export interface OrderProduct {
    product_id: number;
    variation_id: number | null | String;
    quantity: number;
}

export interface PlaceOrder {
    is_redirect: boolean;
    order_number: string;
    transaction_id: string;
    url: string;
}

export interface RePaymentPayload {
    order_number: number,
    payment_method: string
}

export enum OrderStatus {
    Created = 0,
    Progressing = 1,
    //Packing= 2,
    Shipping = 2,
    Done = 3,
    Cancelled = 4,
    Paid = 5,
    PartialPaid = 6,
}

export class ReOrderResponseModel {
    isSuccess: boolean;
    message: string;
    data: ProductAvailabilityResultModel[];
}

export class ProductAvailabilityResultModel {
    productId: number;
    avalibilityType: ProductAvalibilityType;
    message: string | null;
}

export enum ProductAvalibilityType {
    Ok = 10,
    OutOfStock = 20,
    StopSelling = 30
}

export interface PaymentMethod {
    value: string;
    label: string;
}

 export interface OrderItem {
    paymentMethod: PaymentMethod;
}