<!-- WEB -->
<div class="d-none d-lg-block d-xl-block">
  <div class="row pb-3">
    <div class="col-xxl-9 col-lg-9 col-md-12 col-sm-12 pe-2" *ngIf="user">
      <div class="d-grid gap-2 p-3 ps-5 bg-white rounded-3 box-shadow">
        <h5>Mã giới thiệu mời bạn bè</h5>
        <h3 class="fw-bolder d-flex gap-2 align-items-center theme-color pb-2">
          {{user.phone}}
          <button class="bg-transparent border-0" (click)="copyToClipboard(user.phone, 0)">
            <img src="assets/svg/copy 2.svg" style="width: 1.25rem; height: 1.25rem;" />
          </button>
        </h3>
        <h5 class="title-color">Hoặc copy link giới thiệu bạn bè</h5>
        <div class="d-flex align-items-center gap-5 invite-link">
          <h5>{{link}}</h5>
          <button class="bg-transparent border-0" (click)="copyToClipboard(link, 1)">
            <h3><img src="assets/svg/copy 1.svg" style="width: 1rem; height: 1rem;" /></h3>
          </button>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-lg-3 col-md-12 col-sm-12 ps-2 d-grid gap-2" *ngIf="user">
      <div *ngFor="let sum of summary; let i = index" class="d-flex justify-content-center">
        <div class="d-grid gap-2" [class]="i > 1 ? 'border-gradient-white' : 'border-gradient'">
          <div class="d-flex align-items-end gap-1">
            <img [src]="sum.icon" style="width: 2rem; height: 2rem;" />
            <h2 class="text-center fw-bold theme-color" [style]="i < 2 ? 'font-size: 2rem;' : 'font-size: 1.25rem;'">
              {{sum.value.toLocaleString('vi-VN')}}
            </h2>
          </div>
          <h5>{{sum.title}}</h5>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="d-md-block d-sm-block d-lg-none d-xl-none bg-transparent">
  <div class="row">
    <div class="col-xxl-8 col-lg-8 col-md-12 col-sm-12 pb-3" *ngIf="user">
      <div class="d-grid gap-2 p-3 ps-5 bg-white rounded-3 box-shadow">
        <h5>Mã giới thiệu mời bạn bè</h5>
        <h3 class="fw-bolder d-flex gap-2 align-items-center theme-color">
          {{user.phone}}
          <button class="bg-transparent border-0" (click)="copyToClipboard(user.phone, 0)">
            <img src="assets/svg/copy 2.svg" style="width: 1.25rem; height: 1.25rem;" />
          </button>
        </h3>
        <h5 class="title-color">Hoặc copy link giới thiệu bạn bè</h5>
        <div class="invite-link w-100">
          <button class="bg-transparent border-0" (click)="copyToClipboard(link, 1)">
            <h5>{{link}}</h5>  
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12 d-grid gap-4 pb-3 bg-white rounded-3" *ngIf="user">
    <div class="d-grid gap-3">
      <div class="pb-4 g-3 g-sm-4 row row-cols-2 row-cols-lg-2 row-cols-xxl-4">
        <div *ngFor="let sum of summary; let i = index" class="d-flex justify-content-center">
          <div class="d-grid gap-2 border-gradient-white">
            <div class="d-flex align-items-end gap-1">
              <img [src]="sum.icon" style="width: 2rem; height: 2rem;" />
              <h2 class="text-center fw-bold theme-color" [style]="i < 2 ? 'font-size: 2rem;' : 'font-size: 1.25rem;'">
                {{sum.value.toLocaleString('vi-VN')}}
              </h2>
            </div>
            <h5>{{sum.title}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>