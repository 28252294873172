import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ProfileComponent } from "./profile/profile.component";
import { AccountComponent } from "./account.component";
import { OrdersComponent } from "./orders/orders.component";
import { OrderDetailsComponent } from "./orders/details/details.component";
import { WishListComponent } from "./wish-list/wish-list.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AdressesComponent } from "./adresses/adresses.component";
import { BusinessProfileComponent } from "./business-profile/business-profile.component";
import { VoucherComponent } from "./voucher/voucher.component";
import { InviteComponent } from "./invite/invite.component";
import { GiftComponent } from "./gift/gift.component";
import { LoyaltyComponent } from "./loyalty/loyalty.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "profile",
    pathMatch: 'full',
  },
  {
    path: "",
    component: AccountComponent,
    children: [
      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "my-order",
        component: OrdersComponent,
      },
      {
        path: "my-order/:id",
        component: OrderDetailsComponent,
      },
      {
        path: "my-wishlist",
        component: WishListComponent,
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
      },
      {
        path: "my-address",
        component: AdressesComponent,
      },
      {
        path: 'loyalty',
        component: LoyaltyComponent
      },
      {
        path: 'invite',
        component: InviteComponent
      },
      {
        path: 'my-business-profile',
        component: BusinessProfileComponent
      },
      {
        path: 'my-voucher',
        component: VoucherComponent
      },
      {
        path: 'invite',
        component: InviteComponent
      },
      {
        path: 'gift-point',
        component: GiftComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
