<form [formGroup]="form" (ngSubmit)="save()">
  <div
    class="col-xxl-12 col-lg-12 col-md-12 col-sm-12 d-grid gap-4 p-4 bg-white rounded-3"
  >
    <div class="d-grid gap-2">
      <h3 class="fw-bold">Hình thức kinh doanh</h3>
      <div>
        <div class="form-floating">
          <select
            class="form-control ng-untouched ng-pristine ng-invalid"
            id="businessType"
            aria-label="Default select example"
            formControlName="businessType"
            placeholder="Lựa chọn hình thức kinh doanh"
          >
            <option value="" disabled selected>
              Lựa chọn hình thức kinh doanh
            </option>
            <ng-container *ngFor="let business of formOfBusinesses">
              <option
                value="{{ business.code }}"
                [selected]="business.code == selectedBusinessType"
              >
                {{ business.name }}
              </option>
            </ng-container>
          </select>
          <label for="businessType">
            Chọn hình thức kinh doanh
            <span style="color: #dd0000">* </span>
          </label>
          <!-- 
          <input
            type="text"
            class="form-control"
            id="businessType"
            required
            formControlName="businessType"
          />
          <label for="businessType">
            Chọn hình thức kinh doanh
            <span style="color: #dd0000">* </span>
          </label> -->
        </div>
        <label
          *ngIf="form.controls['businessType'].touched && form.controls['businessType'].errors?.['required']"
          class="invalid-feedback"
        >
          Chọn hình thức kinh doanh
        </label>
      </div>
    </div>
    <div class="d-grid gap-2">
      <h3 class="fw-bold">Thông tin cơ sở kinh doanh</h3>
      <div class="d-flex gap-1">
        <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6 pe-1">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="businessName"
              required
              formControlName="businessName"
            />
            <label for="businessName">
              Tên cơ sở kinh doanh
              <span style="color: #dd0000">* </span>
            </label>
          </div>
          <label
            *ngIf="form.controls['businessName'].touched && form.controls['businessName'].errors?.['required']"
            class="invalid-feedback"
          >
            Nhập tên cơ sở kinh doanh
          </label>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6 pe-1">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="taxCode"
              formControlName="taxCode"
            />
            <label for="taxCode">Mã số thuế </label>
          </div>
        </div>
      </div>
      <div class="d-flex gap-1">
        <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6 pe-1">
          <div class="form-floating">
            <!-- <select
              type="email"
              class="form-control"
              id="province"
              required
              formControlName="province"
              (change)="loadDistrictsByProvince($event)"
            >
              <option value="" disabled selected>Tỉnh/Thành phố *</option>
              <ng-container *ngFor="let province of provinces">
                <option
                  value="{{ province.code }}"
                  [selected]="province.code == selectedProvince"
                >
                  {{ province.name }}
                </option>
              </ng-container>
            </select> -->
            <select2
              class="form-control form-select select2"
              id="province"
              aria-label="Default select example"
              formControlName="province"
              (update)="loadDistrictsByProvince($event)"
              [value]="selectedProvince"
              placeholder="Tỉnh/Thành phố *"
              [data]="select2Provinces"
            >
            </select2>
            <label for="province">
              Chọn Tỉnh/Thành phố
              <span style="color: #dd0000">* </span>
            </label>
          </div>
          <label
            *ngIf="form.controls['province'].touched && form.controls['province'].errors?.['required']"
            class="invalid-feedback"
          >
            Chọn Tỉnh/Thành phố
          </label>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6 pe-1">
          <div class="form-floating">
            <!-- <select
              type="email"
              class="form-control"
              id="district"
              required
              formControlName="district"
              (change)="loadWardsByDistrict($event)"
            >
              <option value="" disabled selected>Quận Huyện</option>
              <ng-container *ngFor="let district of districts">
                <option
                  value="{{ district.code }}"
                  [selected]="district.code == selectedDistrict"
                >
                  {{ district.name }}
                </option>
              </ng-container>
            </select> -->
            <select2
              class="form-control form-select select2"
              id="district"
              aria-label="Default select example"
              formControlName="district"
              (update)="loadWardsByDistrict($event)"
              [value]="selectedDistrict"
              placeholder="Quận Huyện *"
              [data]="select2Districts"
            >
            </select2>
            <label for="district">
              Chọn Quận/Huyện
              <span style="color: #dd0000">* </span>
            </label>
          </div>
          <label
            *ngIf="form.controls['district'].touched && form.controls['district'].errors?.['required']"
            class="invalid-feedback"
          >
            Chọn Quận/Huyện
          </label>
        </div>
      </div>
      <div>
        <div class="form-floating">
          <!-- <select
            type="email"
            class="form-control"
            id="ward"
            required
            formControlName="ward"
            (change)="selectWard($event)"
          >
            <option value="" disabled selected>Phường Xã</option>
            <ng-container *ngFor="let ward of wards">
              <option
                value="{{ ward.code }}"
                [selected]="ward.code == selectedWard"
              >
                {{ ward.name }}
              </option>
            </ng-container>
          </select> -->
          <select2
              class="form-control form-select select2"
              id="ward"
              aria-label="Default select example"
              formControlName="ward"
              placeholder="Phường Xã *"
              [value]="selectedWard"
              [data]="select2Wards"
            >
            </select2>
          <label for="ward">
            Chọn Phường/Xã
            <span style="color: #dd0000">* </span>
          </label>
        </div>
        <label
          *ngIf="form.controls['ward'].touched && form.controls['ward'].errors?.['required']"
          class="invalid-feedback"
        >
          Chọn Phường/Xã
        </label>
      </div>
      <div>
        <div class="form-floating">
          <input
            type="email"
            class="form-control"
            id="address"
            formControlName="address"
          />
          <label for="address">Địa chỉ (số nhà, tên đường)</label>
        </div>
      </div>
    </div>
    <div class="d-grid gap-4">
      <h3 class="fw-bold">Giấy phép đăng ký kinh doanh</h3>
      <div *ngIf="this.businessLicensesImage;  else uploadfile">
        <a href="{{ businessLicensesImage }}" target="_blank">
          <img
            src="{{ businessLicensesImage }}"
            alt="image"
            class="img-fluid"
            style="width: 100px; height: 100px"
        /></a>
        <a href="javascript:void(0)" (click)="remove(1, 'businessLicenses')"><i class="ri-close-line text-red"></i></a>
        
        
      </div>

      <ng-template #uploadfile>
        <app-file-upload
          [multipleFiles]="false"
          [files]="businessProfile.businessLicenses"
          [fileType]="'*'"
          [title]="'Nhấn tải lên giấy chứng nhận của bạn tại đây.'"
          [description]="'Định dạng PNG, JPEG, JPG, PDF dung lượng dưới 10MB'"
          (select)="onSelect($event, 0)"
          (remove)="onRemove($event, 0)"
          formControlName="businessLicenses"
          ngDefaultControl
        ></app-file-upload>
      </ng-template>

      <label
        *ngIf="form.controls['businessLicenses'].touched && form.controls['businessLicenses'].errors?.['required']"
        class="invalid-feedback"
      >
        Thêm giấy phép đăng ký kinh doanh
      </label>
    </div>
    <div class="d-grid gap-4">
      <h3 class="fw-bold">Giấy chứng nhận đủ điều kiện kinh doanh dược</h3>
      <div *ngIf="this.businessCertificationsImage; else uploadfileCert">

        <a href="{{ businessCertificationsImage }}" target="_blank">
          <img
            src="{{ businessCertificationsImage }}"
            alt="image"
            class="img-fluid"
            style="width: 100px; height: 100px"
        /></a>
        <a href="javascript:void(0)" (click)="remove(1, 'businessCertifications')"><i class="ri-close-line text-red"></i></a>
      </div>
      <ng-template #uploadfileCert>
        <app-file-upload
        [multipleFiles]="false"
        [files]="businessProfile.businessCertifications"
        [fileType]="'*'"
        [title]="'Nhấn tải lên giấy chứng nhận của bạn tại đây.'"
        [description]="'Định dạng PNG, JPEG, JPG, PDF dung lượng dưới 10MB'"
        (select)="onSelect($event, 1)"
        (remove)="onRemove($event, 1)"
        formControlName="businessCertifications"
        ngDefaultControl
      ></app-file-upload>
      </ng-template>
      
      <label
        *ngIf="form.controls['businessCertifications'].touched && form.controls['businessCertifications'].errors?.['required']"
        class="invalid-feedback"
      >
        Thêm giấy chứng nhận đủ điều kiện kinh doanh dược
      </label>
    </div>
    <div class="d-grid gap-4">
      <h3 class="fw-bold">
        Giấy chứng nhận đạt thực hành tốt phân phối thuốc, nguyên liệu làm thuốc (GPP)
      </h3>
      <div *ngIf="this.distributionCertificationsImage; else uploadfileStandard">

        <a href="{{ distributionCertificationsImage }}" target="_blank">
          <img
            src="{{ distributionCertificationsImage }}"
            alt="image"
            class="img-fluid"
            style="width: 100px; height: 100px"
        /></a>
        <a href="javascript:void(0)" (click)="remove(1, 'distributionCertifications')"><i class="ri-close-line text-red"></i></a>
      </div>
      <ng-template #uploadfileStandard>
        <app-file-upload
        [multipleFiles]="false"
        [files]="businessProfile.distributionCertifications"
        [fileType]="'*'"
        [title]="'Nhấn tải lên giấy chứng nhận của bạn tại đây.'"
        [description]="'Định dạng PNG, JPEG, JPG, PDF dung lượng dưới 10MB'"
        (select)="onSelect($event, 2)"
        (remove)="onRemove($event, 2)"
        formControlName="distributionCertifications"
        ngDefaultControl
      ></app-file-upload>
      </ng-template>
      
      <label
        *ngIf="form.controls['distributionCertifications'].touched && form.controls['distributionCertifications'].errors?.['required']"
        class="invalid-feedback"
      >
        Thêm giấy chứng nhận đạt thực hành tốt phân phối thuốc, nguyên liệu làm
        thuốc
      </label>
    </div>
    <div class="d-flex justify-content-center pt-5">
      <div class="col-xxl-4 col-md-4 col-lg-4 col-sm-4">
        <button class="btn save-button">Yêu cầu phê duyệt hồ sơ</button>
      </div>
    </div>
  </div>
</form>
