import { Attachment } from "./attachment.interface";
import { Permission, Role } from "./role.interface";
import { Point } from "./point.interface";
import { UserAddress } from "./user.interface";

export interface AccountUser {
    id: number;
    name: string;
    email: string;
    phone: string;
    country_code: string;
    profile_image?: Attachment;
    profile_image_id?: number;
    status: boolean;
    email_verified_at: string;
    role_id: number;
    role_name?: string;
    role?: Role;
    permission: Permission[];
    address?: UserAddress[];
    point?: Point;
    orders_count: number;
    is_approved: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface AccountUserUpdatePassword {
    current_password: string;
    new_password: string;
    confirm_password: string;
}

export class BusinessProfile {
    userName: string;
    businessType: string;
    businessName: string;
    taxCode: string;
    province: string;
    district: string;
    ward: string;
    address: string;
    businessLicenses: File[] = [];
    businessCertifications: File[] = [];
    distributionCertifications: File[] = [];
}