import { Affiliate, User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrl: './invite.component.scss'
})
export class InviteComponent {

  user: User;
  link: string = "https://giathuoctot.com?ref=";

  invitedList: any[] = [
    // {
    //   phone: "0917618369",
    //   createdDate: new Date("2024/01/30 11:33:01"),
    //   value: 0
    // },
    // {
    //   phone: "0922345341",
    //   createdDate: new Date("2024/01/23 11:33:01"),
    //   value: 0
    // },
    // {
    //   phone: "0912345678",
    //   createdDate: new Date("2024/01/12 11:33:01"),
    //   value: 0
    // },
    // {
    //   phone: "0986789999",
    //   createdDate: new Date("2024/01/23 11:33:01"),
    //   value: 0
    // },
  ];

  summary: any[] = [
    {
      title: "Tổng 1",
      value: 0,
      icon: "assets/svg/profile-2user.svg"
    },
    {
      title: "Tổng 2",
      value: 0,
      icon: "assets/svg/profile-2user.svg"
    },
  ]

  headerColumns: any = [
    {
      title: "Số điện thoại",
      size: 3
    },
    {
      title: "Ngày tạo tài khoản",
      size: 6
    },
    {
      title: "Số người giới thiệu",
      size: 3
    },
  ];

  constructor(
    private accountService: AccountService,
    private toastService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.accountService.getUserData.subscribe({
      next: (res: User) => {
        if (res.id) {
          this.user = res;
          this.link += this.user.phone;
        }
      }
    });

    this.getInviteInfo();
  }

  getInviteInfo() {
    this.accountService.getAffiliate().subscribe(
      (res: Affiliate) => {
        this.summary[0].value = res.totalDirectPartner;
        this.summary[1].value = res.totalIndirectPartner;
      },
      (error) => {
        this.toastService.error(error.error.message);
      }
    );
  }

  copyToClipboard(value: string, type: number) {
    let msg = "";
    if (!type) {
      msg = "Sao chép mã giới thiệu thành công!";
    }
    else {
      msg = "Sao chép link giới thiệu thành công!";
    }
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(value);
    }
    this.toastService.success(msg, "", { positionClass: "toast-top-right" });
  }
}
