import { Component } from '@angular/core';
// import { Store, Select } from '@ngxs/store';
import { Observable, Subject, Subscription, debounceTime } from 'rxjs';
// import { OrderState } from '../../../shared/state/order.state';
// import { GetOrders } from '../../../shared/action/order.action';
// import { OrderModel } from '../../../shared/interface/order.interface';
import { Params } from '../../../shared/interface/core.interface';
import { OrderService } from '@/app/shared/services/order.service';
import { Order, OrderItem, OrderModel, OrderStatus } from '@/app/shared/interface/order.interface';
import { CatetoryQueryString, PageQueryString, SearchUserQueryString } from '@/app/shared/filter';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationModel, RetriveUserModel } from '@/app/shared/interface/product.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {

  public orders: Order[] = [];
  public total: number = 0;
  public orderNumber: Order[] = [];

  public skeletonLoader: boolean = false;
  public skeletonItems = Array.from({ length: 40 }, (_, index) => index);

  public inputText: string;
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)

  private searchSubject = new Subject<string>();
  private changeQueryStringSubcription?: Subscription;
  public searchUserQuery: SearchUserQueryString = new SearchUserQueryString();
  public categoryQuery: CatetoryQueryString = new CatetoryQueryString();

  public filters = [
    {
      name: "Tất cả",
      active: true,
      value: 0,
      type: -1
    },
    {
      name: "Chờ xử lý",
      active: false,
      value: 0,
      type: 0
    },
    {
      name: "Đang xử lý",
      active: false,
      value: 0,
      type: 1
    },
    // {
    //   name: "Đã đóng gói",
    //   active: false,
    //   value: 0,
    //   type: 2
    // },
    {
      name: "Đang vận chuyển",
      active: false,
      value: 0,
      type: 3
    },
    {
      name: "Đã hoàn thành",
      active: false,
      value: 0,
      type: 4
    },
    {
      name: "Đã hủy",
      active: false,
      value: 0,
      type: 5
    },
  ];

  headerColumns = [
    "Mã đơn hàng",
    "Tổng tiền",
    "Thời gian",
    "Trạng thái",
    "Thông tin đơn hàng"
  ];

  bodyProduct = {
    limit: 10,
    offset: 0,
  };

  constructor(
    private orderService: OrderService,
    private queryStringService: QueryStringService,
    private route: ActivatedRoute,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) {
    // this.store.dispatch(new GetOrders(this.filter));
  }
  

  ngOnInit() {
    this.categoryQuery = this.queryStringService.getQueryString(new CatetoryQueryString());
    this.changeQueryStringSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.searchUserQuery = this.queryStringService.getQueryString(new SearchUserQueryString());
    });

    this.route.queryParams.subscribe(params => {
      if (params["s"]) {
        this.inputText = params["s"];
        this.search(this.inputText);
      }
      else {
        this.getNumberOfOrder();
        this.getUserOrders();
      }
      if (params["category"]) {
        this.setActive(params["category"]);
      }
    });

    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.searchUserQuery.value = this.inputText;
      this.queryStringService.changeQueryString(this.searchUserQuery);
    });

  }

  getNumberOfOrder() {
    let body = {
      searchTerm: "",
      sortBy: "",
      category: "",
    }
    this.orderService.getOrders(JSON.stringify(body)).subscribe({
      next: (res: OrderModel) => {
        this.orderNumber = res.orderHistory;
      }
    })
  }


  getUserOrders() {
    let body = this.queryStringService.buildFetchingModel(this.bodyProduct.limit);
    this.skeletonLoader = true;
    this.orderService.getOrders(JSON.stringify(body)).subscribe({
      next: (res: OrderModel) => {
        if (res.orderHistory && res.orderHistory?.length > 0) {
          this.orders = res.orderHistory;
          this.total = res.total;
          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: "",
            totalItems: res.total,
            sortBy: "",
            category: "",
          };
          setTimeout(() => {
            this.queryStringService.changePagination(pagination);
          }, 10);
        }
        interface FilterTypeToOrderStatus {
          0: OrderStatus.Created,
          1: OrderStatus.Progressing,
          //2: OrderStatus.Packing,
          3: OrderStatus.Shipping,
          4: OrderStatus.Done,
          5: OrderStatus.Cancelled,
          6: OrderStatus.Paid,
          7: OrderStatus.PartialPaid
        }

        const filterTypeToOrderStatus: FilterTypeToOrderStatus = {
          0: OrderStatus.Created,
          1: OrderStatus.Progressing,
          //2: OrderStatus.Packing,
          3: OrderStatus.Shipping,
          4: OrderStatus.Done,
          5: OrderStatus.Cancelled,
          6: OrderStatus.Paid,
          7: OrderStatus.PartialPaid
        };

        for (let filter of this.filters) {
          const orderStatus = filterTypeToOrderStatus[filter.type as keyof FilterTypeToOrderStatus];
          if (orderStatus !== undefined) {
            filter.value = this.orderNumber.filter(o => o.orderStatus === orderStatus)?.length;
          } else {
            filter.value = this.orderNumber?.length;
          }
        }
      }
    })
  }

  search(value: string) {
    let body: any;
    if (value) {
      body = {
        orderId: value
      }
    }
    else {
      // let request = this.queryStringService.buildFetchingModel();
      body = {
        limit: 10,
        offset: 0,
        searchTerm: value,
        maxNumberOfResult: 20
      }
    }

    this.orderService.getOrders(JSON.stringify(body)).subscribe({
      next: (res: any) => {
        this.orders = res.orderHistory;
        let pagination: PaginationModel = {
          limit: body.limit,
          offset: body.offset,
          searchTerm: body.searchTerm,
          totalItems: res.total,
        };
        this.queryStringService.changePagination(pagination);
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        this.skeletonLoader = false;
      }
    })
  }

  input() {
    if (this.inputText) {
      this.searchSubject.next(this.inputText);
    }
    else if (this.inputText != undefined) {
      this.searchSubject.next(this.inputText);
      this.getUserOrders();
    }
  }

  setActive(categories: string) {
    const categoryNames = categories.split(",");
    for (let filter of this.filters) {
      if (categoryNames.includes(filter.name)) {
        filter.active = true;
      }
    }
  }

  filter(e: any) {
    e.active = !e.active;
    this.changeQueryString();
  }

  changeQueryString() {
    let s = '';
    for (const c of this.filters) {
      if (c.active && c.type != -1) {
        s += `${c.name},`;
      }
    }
    this.categoryQuery.value = s.substring(0, s.length - 1);
    this.queryStringService.changeQueryString(this.categoryQuery);
  }

  getOrderStatus(status: OrderStatus) {
    switch (status) {
      case OrderStatus.Created:
        return { value: "Đang xác nhận", icon: "created" };
      case OrderStatus.Progressing:
        return { value: "Đang xử lý", icon: "progressing" };
      // case OrderStatus.Packing:
      //   return { value: "Đã Đóng Gói", icon: "progressing"}
      case OrderStatus.Shipping:
        return { value: "Đang vận chuyển", icon: "shipping" };
      case OrderStatus.Done:
        return { value: "Đã Hoàn Thành", icon: "done" };
      case OrderStatus.Cancelled:
        return { value: "Đã hủy", icon: "cancelled" };
      case OrderStatus.Paid:
        return { value: "Đã thanh toán", icon: "paid" };
      case OrderStatus.PartialPaid:
        return { value: "Thanh toán một phần", icon: "partial-paid" };
    }
  }

  getTotalItems(items: OrderItem[]) {
    let itemsCount = items?.length, totalQuantity = 0;
    totalQuantity = items.reduce((current, next) => {
      return current + next.quantity;
    }, 0);
    return this._sanitizer.bypassSecurityTrustHtml(`Sản phẩm: <span style="color: #000000;">${itemsCount}</span> - Tổng SL <span style="color: #000000;">${totalQuantity}</span>`);
  }

  gotoDetail(order: Order) {
    this.orderService.setlectedOrder = order;
  }

  ngOnDestroy() {
    this.changeQueryStringSubcription?.unsubscribe();
  }
}
