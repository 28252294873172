<app-breadcrumb [breadcrumb]="breadcrumb" class="d-none d-lg-block d-xl-block"></app-breadcrumb>
<section class="user-dashboard-section section-b-space">
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-xxl-3 col-lg-4">
                <div class="d-none d-xl-block d-lg-block dashboard-left-sidebar bg-white border shadow-none" [class.show]="open">
                    <app-sidebar [show]="open" (menu)="openMenu($event)" (activeMenu)="setBreadcrumb($event)"></app-sidebar>
                </div>
            </div>
            <div class="col-xxl-9 col-lg-8 custom-box-loader" id="account">
                <!-- <div class="box-loader" *ngIf="commonService.showLoader">
                    <app-loader [loaderClass]="'custom-loader-wrapper blur-bg'"></app-loader>
                </div> -->
                <ng-container>
                    <!-- <app-button
                      [class]="'btn left-dashboard-show btn-animation btn-md fw-bold d-block mb-4 d-lg-none'"
                      [type]="'button'"
                      [id]="'Menu'"
                      (click)="openMenu(true)"
                      [spinner]="false">
                        {{ 'Menu' | translate }}
                    </app-button> -->
                    <div class="d-lg-none d-xl-none pb-3" *ngIf="breadcrumb.items.length > 1">
                        <div class="d-flex align-items-center gap-2">
                            <img src="assets/svg/{{ breadcrumb.items.at(-1)?.icon}}" style="filter: brightness(0) saturate(100%) invert(37%) sepia(14%) saturate(2158%) hue-rotate(81deg) brightness(94%) contrast(87%);" [alt]="breadcrumb.items.at(-1)?.label"/>
                            <span class="fs-5" style="color: #247636;">{{ breadcrumb.items.at(-1)?.label }}</span>
                        </div>
                    </div>
                    <div class="dashboard-right-sidebar py-0">
                        <div class="tab-content" >
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>
