<div *ngIf="order" class="ms-3 mobile-mg-0">
  <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12 d-grid gap-3 bg-white p-4 rounded-3 mobile-pd d-flex">
    <div class="col-8">
      <div class="d-flex align-items-center gap-2">
        <h3>Đơn Hàng {{ order.orderId }}</h3>
        <img src="assets/svg/copy 1.svg" alt="Copy orderId" (click)="copyToClipboard(order.orderId)" />
      </div>
      <div class="d-flex align-items-center w-100 d-none d-xl-flex d-lg-flex pt-3">
        <div class="col-4 text-gray d-grid gap-2">
          <h5>Ngày mua</h5>
          <h5>Trạng thái đơn hàng</h5>
        </div>
        <div class="col-8 d-grid gap-2">
          <h5>{{order.orderDate | date: 'HH:mm:ss EEEE (dd/MM/yyyy)':undefined:'vi'}}</h5>
          <span class="d-flex align-items-center gap-1">
            <ng-container *ngTemplateOutlet="
                template;
                context: { status: getOrderStatus(order.orderStatus) }
              "></ng-container>
            <ng-template #template let-status="status">
              <span class="dot {{
                  status.icon
                }} d-flex align-items-center justify-content-center"><span
                  class="inner-dot {{ status.icon }}"></span></span>
              <h5 class="{{ status.icon }}">{{ status.value }}</h5>
            </ng-template>
          </span>
        </div>
      </div>
      <div class="d-lg-none d-xl-none">
        <div class="d-flex w-100 gap-2">
          <h5 class="text-gray">Ngày mua:</h5>
          <h5>{{order.orderDate | date: 'HH:mm:ss EEEE (dd/MM/yyyy)':undefined:'vi'}}</h5>
        </div>
        <div class="d-flex w-100 gap-2">
          <h5 class="text-gray">Trạng thái đơn hàng:</h5>
          <span class="d-flex align-items-center gap-1">
            <ng-container *ngTemplateOutlet="
                template;
                context: { status: getOrderStatus(order.orderStatus) }
              "></ng-container>
            <ng-template #template let-status="status">
              <span class="dot {{
                  status.icon
                }} d-flex align-items-center justify-content-center"><span
                  class="inner-dot {{ status.icon }}"></span></span>
              <h5 class="{{ status.icon }}">{{ status.value }}</h5>
            </ng-template>
          </span>
        </div>
      </div>
      <div class="col-12 pt-3">
        <h5 class="text-gray">Địa Chỉ Và Thông Tin Người Nhận Hàng</h5>
        <ul class="ps-4 pt-3 d-grid gap-2">
          <li>
            <h5>Tên Người Nhận: {{ order.receiverInfo.userName }}</h5>
          </li>
          <li>
            <h5>Địa Chỉ Giao Hàng: {{ order.receiverInfo.address }}</h5>
          </li>
          <li>
            <h5>Số Điện Thoại: {{ order.receiverInfo.phone }}</h5>
          </li>
          <li>
            <h5>
              Email:
              <span *ngIf="order.receiverInfo.email; else noEmail">
                {{ order.receiverInfo.email }}
              </span>
              <ng-template #noEmail> (Chưa có) </ng-template>
            </h5>
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center w-100 d-none d-xl-flex d-lg-flex pt-3">
        <div class="col-4 text-gray d-grid gap-2">
          <h5>Hình Thức Thanh Toán:</h5>
          <h5>Ghi chú:</h5>
        </div>
        <div class="col-8 d-grid gap-2">
          <h5>
            <span *ngIf="order.paymentMethod; else noPaymentMethod">
              {{ order.paymentMethod}}
            </span>
            <ng-template #noPaymentMethod> Chuyển khoản ngân hàng </ng-template>
          </h5>
          <h5>
            <span *ngIf="order.note; else noNote">
              {{ order.note }}
            </span>
            <ng-template #noNote> Không có </ng-template>
          </h5>
        </div>
      </div>
      <div class="d-lg-none d-xl-none">
        <div class="d-flex w-100 gap-2">
          <h5 class="text-gray">Hình Thức Thanh Toán:</h5>
          <h5>
            <span *ngIf="order.paymentMethod; else noPaymentMethod">
              {{ order.paymentMethod}}
            </span>
            <ng-template #noPaymentMethod> Chuyển khoản ngân hàng </ng-template>
          </h5>
        </div>
        <div class="d-flex w-100 gap-2">
          <h5 class="text-gray">Ghi chú:</h5>
          <h5>
            <span *ngIf="order.note; else noNote">
              {{ order.note }}
            </span>
            <ng-template #noNote> Không có </ng-template>
          </h5>
        </div>
      </div>
    </div>
  </div>

  <section>
    <div class="bg-white p-4 rounded-3 mobile-pd">
      <div class="col-xxl-5 col-lg-5 col-md-5 col-sm-5 d-grid gap-3 bg-white p-4 rounded-3 mobile-pd d-flex" style="margin-bottom: 15px;" *ngIf="order.orderStatus == 0 && order.paymentMethod">
        <div style="width: 70%;">
          <app-qr-code [qrImgSrc]="qrImgSrc"></app-qr-code>
          <div class="pt-3 reorder-container">
            <button 
              class="btn btn-sm btn-success d-flex align-items-center text-start gap-2 px-2 py-1 border-0"
              style="border-radius: 5px; white-space: wrap;"
              (click)="downloadQRCode()" 
            >
              <img src="assets/svg/download_qr_white.svg" alt="download QR" style="width: 23px; height: 23px;" class="justify-content-center"/>
              <h6 class="text-white">Tải xuống</h6>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-xs-12 fs-4 my-auto">Danh Sách Sản Phẩm ({{ order.items.length }} Sản Phẩm)</div>
        <div class="col-md-4 col-xs-12 reorder-container">
          <button type="button" class="btn btn-sm btn-success" (click)="reOrder()">{{'Đặt hàng lại' |
            translate}}</button>
        </div>
      </div>
      <div class="card ps-0 pe-0" *ngIf="order.items.length">
        <div class="card-body">
          <div class="tracking-wrapper table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th *ngFor="let col of headerColumns" scope="col" class="d-none d-xl-table-cell d-lg-table-cell">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of order.items">
                  <td class="col-xxl-6 col-lg-7 col-md-7 col-sm-7 product-image">
                    <div class="d-flex align-items-center gap-3">
                      <img style="width: 4rem" [src]="
                          item.imgUrl
                            ? item.imgUrl
                            : 'assets/images/product.png'
                        " class="img-fluid" alt="product" />
                      <div class="d-grid gap-2">
                        <a [routerLink]="['/product/', item.productID ]" class="link">
                          <h6 class="fw-bold text-black">{{ item.name }}</h6>
                        </a>
                        <div class="d-lg-none d-xl-none d-grid gap-2">
                          <h6><span class="text-gray">Số lượng khách đặt: </span>{{ item.quantity }}</h6>
                          <h6><span class="text-gray">Số lượng giao thực tế: </span>{{ item.quantity }}</h6>
                          <h6><span class="text-gray">Đơn giá (đ): </span>{{ item.price }}</h6>
                          <h6><span class="text-gray">Thành tiền (đ): </span>{{ item.totalPrice | currencySymbol }}</h6>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="d-none d-xl-table-cell d-lg-table-cell">
                    <h6>{{ item.quantity }}</h6>
                  </td>
                  <td class="d-none d-xl-table-cell d-lg-table-cell">
                    <h6>{{ item.quantity }}</h6>
                  </td>
                  <td class="d-none d-xl-table-cell d-lg-table-cell">
                    <h6>{{ item.price | currencySymbol }}</h6>
                  </td>
                  <td class="d-none d-xl-table-cell d-lg-table-cell">
                    <h6>{{ item.totalPrice | currencySymbol }}</h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end text-end mobile-detail-bottom">
        <div class="col-5 d-grid gap-3 mobile-w-100">
          <div class="d-flex align-items-center w-100">
            <div class="col-6 text-gray d-grid gap-4 text-start">
              <h5 class="fw-bold">Tạm Tính</h5>
            </div>
            <div class="col-6 d-grid gap-4">
              <h5>
                {{ order.sumTotalPrice | currencySymbol }}
              </h5>
            </div>
          </div>
          <div class="d-flex align-items-center w-100">
            <div class="col-8 text-gray d-grid gap-4 text-start">
              <span class="d-flex align-items-center gap-4">
                <h5 class="fw-bold">Giá giảm voucher</h5>
                <span *ngIf="order.appliedVoucher" style="color: #247636">{{
                  order.appliedVoucher.code
                  }}</span>
              </span>
            </div>
            <div class="col-4 gap-4">
              <h5>
                <span *ngIf="order.voucherDiscountAmount; else noVoucher">
                  - {{ order.voucherDiscountAmount | currencySymbol }}
                </span>
                <ng-template #noVoucher> Không có </ng-template>
              </h5>
            </div>
          </div>
          <div class="d-flex align-items-center w-100">
            <div class="col-6 text-gray gap-4 text-start">
              <h5 class="fw-bold">Tổng cộng</h5>
            </div>
            <div class="col-6 gap-4">
              <h5 class="fs-5 fw-bold">
                {{ order.finalPrice | currencySymbol }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>