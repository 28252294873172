<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="col-xxl-12 col-xl-12 col-lg-12 d-grid gap-4 pt-4 bg-white rounded-4">
    <div class="d-flex justify-content-center align-items-center pt-4">
      <div
        class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8 border border-2 rounded-4 p-4"
      >
        <div class="d-grid gap-3">
          <h3>Mật khẩu hiện tại</h3>
          <div class="input-group">
            <input
              #password
              type="password"
              id="password"
              formControlName="password"
              class="form-control profile-input"
              placeholder="Nhập mật khẩu hiện tại"
              style="border-right: none"
            />

            <span
              class="input-group-text"
              style="background: none"
              (click)="password.type = password.type == 'password' ? 'text' : 'password'"
              ><img #icon [src]="password.type=='password'?'assets/svg/register/Eye_duotone.svg':'assets/svg/register/View_hide_light.svg'"  /></span>

          </div>
          <div
                *ngIf="form.controls['password'].touched && form.controls['password'].errors?.['required']"
                class="invalid-feedback"
              >
                Nhập mật khẩu
              </div>
              
          <h3>Mật khẩu mới</h3>
          <div class="input-group">
            <input
              #newpassword
              type="password"
              id="newpassword"
              formControlName="newpassword"
              class="form-control profile-input"
              placeholder="Nhập mật khẩu mới"
              style="border-right: none"
            />

            <span
              class="input-group-text"
              style="background: none"
              (click)="newpassword.type = newpassword.type == 'password' ? 'text' : 'password'"
              ><img #icon [src]="newpassword.type=='password'?'assets/svg/register/Eye_duotone.svg':'assets/svg/register/View_hide_light.svg'"  /></span>

          </div>
          <div
                *ngIf="form.controls['newpassword'].touched && form.controls['newpassword'].errors?.['required']"
                class="invalid-feedback"
              >
                Nhập mật khẩu
              </div>
              <div
              
                *ngIf="form.controls['newpassword'].touched && form.controls['newpassword'].value.length > 0 &&
                (form.controls['newpassword'].value.length < 6 ||
                form.controls['newpassword'].errors?.['pattern']
                )"
                class="invalid-feedback"
              >
                <img src="/assets/svg/register/Alarm_fill.svg" />
                Mật khẩu phải từ 6 ký tự trở lên.
              </div>
          <h3>Nhập lại mật khẩu mới</h3>
          <div class="input-group">
            <input
              #renewpassword
              type="password"
              id="renewpassword"
              formControlName="renewpassword"
              class="form-control profile-input"
              placeholder="Nhập lại mật khẩu mới"
              style="border-right: none"
            />

            <span
              class="input-group-text"
              style="background: none"
              (click)="renewpassword.type = renewpassword.type == 'password' ? 'text' : 'password'"
              ><img #icon [src]="renewpassword.type=='password'?'assets/svg/register/Eye_duotone.svg':'assets/svg/register/View_hide_light.svg'"  /></span>
          </div>
          <div
                *ngIf="form.controls['renewpassword'].touched && form.controls['renewpassword'].errors?.['required']"
                class="invalid-feedback"
              >
                Nhập mật khẩu
              </div>
              <div
                *ngIf="form.controls['renewpassword'].touched && form.controls['renewpassword'].value.length > 0 &&
                (form.controls['renewpassword'].value.length < 6 ||
                form.controls['renewpassword'].errors?.['pattern']
                )"
                class="invalid-feedback"
              >
                <img src="/assets/svg/register/Alarm_fill.svg" />
                Mật khẩu phải từ 6 ký tự trở lên.
              </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center pb-4">
      <div
        class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8"
      >
        <div class="d-flex justify-content-center pt-5">
          <div class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8">
            <button class="btn profile-button">Lưu thay đổi</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


