<div class="p-4 bg-white rounded-3 mobile-pd">
  <div class="title-header">
    <div class="d-flex align-items-center w-100 p-0">
      <div class="input-group align-items-center border div-search">
        <input
          #inputSearch
          id="quickSearchInput"
          type="search"
          class="form-control border-0 bg-transparent search-input"
          placeholder="Nhập mã đơn hàng"
          [(ngModel)]="inputText"
          (keyup)="input()"
        />
        <button
          class="border-0 bg-transparent p-0"
          (click)="search(inputSearch.value)"
        >
          <i
            class="ri-search-line d-flex justify-content-center align-items-center search-icon"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="d-flex align-items-center w-100 ps-3 pe-3 pt-4 gap-4 overflow-auto"
    >
      <button
        *ngFor="let btn of filters"
        class="div-tag pe-3 ps-3 pt-2 pb-2 bg-white mb-1"
        [class]="btn.active ? 'active' : ''"
        (click)="filter(btn)"
      >
        <h6 class="w-max-content">{{ btn.name }} ({{ btn.value }})</h6>
      </button>
    </div>
  </div>
  <div class="total-box mt-0" *ngIf="orders.length">
    <div class="wallet-table mt-0 p-0">
      <div class="table-responsive">
        <table>
          <thead>
            <tr>
              <th *ngFor="let col of headerColumns" class="d-none d-xl-table-cell d-lg-table-cell">{{ col }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of orders; let i = index">
              <!-- mobile -->
              <td class="d-xl-none d-lg-none">
                <div class="d-flex justify-content-between">
                  <div>
                    <a
                      [routerLink]="['/account/my-order', order.orderId ]"
                      class="theme-color fw-bold"
                      (click)="gotoDetail(order)"
                      >{{ order.orderId }}</a
                    >
                    <div>
                      {{ order.orderDate | date: 'HH:mm:ss dd/MM/yyyy' }}
                    </div>
                    <div class="fw-bold">
                      {{ order.finalPrice | currencySymbol }}
                    </div>
                    <h6
                      [innerHTML]="getTotalItems(order.items)"
                      style="color: #6a6a6a"
                    ></h6>
                    <div
                      *ngIf="order.appliedVoucher"
                      class="d-flex gap-1 align-items-center"
                    >
                      <img src="assets/svg/coupon 1.svg" style="max-width: 17px" />
                      <span style="color: var(--theme-color)"
                        ><h6>{{ order.appliedVoucher.code }}</h6></span
                      >
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                      <ng-container
                        *ngTemplateOutlet="
                          template;
                          context: { status: getOrderStatus(order.orderStatus) }
                        "
                      ></ng-container>
                      <ng-template #template let-status="status">
                        <div class="icon {{ status.icon }}"></div>
                        {{ status.value }}
                      </ng-template>
                    </div>
                    <a
                      class="text-end"
                      [routerLink]="['/account/my-order', order.orderId ]"
                      (click)="gotoDetail(order)"
                      ><span class="mobile-button">Xem chi tiết</span></a
                    >
                  </div>
                </div>
              </td>
              <!-- desktop -->
              <td class="d-none d-xl-table-cell d-lg-table-cell">
                <a
                  [routerLink]="['/account/my-order', order.orderId ]"
                  class="theme-color fw-bold"
                  (click)="gotoDetail(order)"
                  >{{ order.orderId }}</a
                >
              </td>
              <td class="d-none d-xl-table-cell d-lg-table-cell">
                <div class="fw-bold">
                  {{ order.finalPrice | currencySymbol }}
                </div>
                <div>
                  <h6
                    [innerHTML]="getTotalItems(order.items)"
                    style="color: #6a6a6a"
                  ></h6>
                </div>
                <div
                  *ngIf="order.appliedVoucher"
                  class="d-flex gap-1 align-items-center"
                >
                  <img src="assets/svg/coupon 1.svg" style="max-width: 17px" />
                  <span style="color: var(--theme-color)"
                    ><h6>{{ order.appliedVoucher.code }}</h6></span
                  >
                </div>
              </td>
              <td class="d-none d-xl-table-cell d-lg-table-cell">{{ order.orderDate | date : "hh:mm:ss dd/MM/yyyy " }}</td>
              <td class="d-none d-xl-table-cell d-lg-table-cell">
                <div class="d-flex align-items-center gap-2">
                  <ng-container
                    *ngTemplateOutlet="
                      template;
                      context: { status: getOrderStatus(order.orderStatus) }
                    "
                  ></ng-container>
                  <ng-template #template let-status="status">
                    <div class="icon {{ status.icon }}"></div>
                    {{ status.value }}
                  </ng-template>
                </div>
              </td>
              <td class="d-none d-xl-table-cell d-lg-table-cell">
                <a
                  [routerLink]="['/account/my-order', order.orderId ]"
                  (click)="gotoDetail(order)"
                  >Xem chi tiết</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <nav class="custome-pagination">
    <app-collection-paginate> </app-collection-paginate>
  </nav>
</div>
<app-no-data
  [class]="'no-data-added'"
  [image]="'assets/svg/empty-items.svg'"
  [text]="'Bạn chưa có đơn hàng nào!'"
  [description]="'Hãy đặt hàng ngay nhé!'"
  *ngIf="!orders.length"
>
</app-no-data>
