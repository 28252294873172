<div class="dashboard-address p-4 bg-white">
  <div class="title-header">
    <div class="d-flex align-items-center w-100 justify-content-between">
      <app-button
        [class]="'btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 ms-auto'"
        [type]="'button'"
        [spinner]="false"
        [id]="'add_address'"
        (click)="addAddress()"
      >
        <i class="ri-add-line"></i> Thêm địa chỉ
      </app-button>
    </div>
  </div>
  <div class="row g-sm-4 g-3" *ngIf="addresses.length">
    <div
      class="col-xxl-4 col-xl-6 col-lg-12 col-md-6"
      *ngFor="let address of addresses; let i = index"
    >
      <div class="address-box d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-end" style="min-height: 22px;" >
          <div class="label-top" *ngIf="address?.status == 0">
            <label>Mặc định</label>
          </div>
        </div>
        <div>
          <div class="table-responsive address-table">
            <table class="table g-3">
              <tbody>
              <tr></tr>
              <tr>
                <td>Tên :</td>
                <td>
                  <p class="ps-2">
                    {{ address?.receiverName }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>Điện thoại :</td>
                <td>
                  <p class="ps-2">{{ address?.receiverPhone }}</p>
                </td>
              </tr>
              <tr>
                <td>Địa chỉ :</td>
                <td>
                  <p class="ps-2">{{ address?.address }}, {{address?.province?.name}}, {{address?.province?.districts?.[0]?.name}}, {{address?.province?.districts?.[0]?.wards?.[0]?.name}}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="button-group">
          <app-button
            [class]="'btn btn-sm add-button'"
            [type]="'button'"
            [spinner]="false"
            [id]="'edit_address'"
            (click)="onEditAddress(address)"
          >
            <i class="ri-edit-box-line"></i> Sửa đổi
          </app-button>
          <app-button
            [class]="'btn btn-sm add-button'"
            [type]="'button'"
            [spinner]="false"
            [id]="'remove_address'"
            (click)="onDeleteAddress(address)"
          >
            <i class="ri-delete-bin-line"></i> Xóa
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>


<address-modal [address]="addressEdit" [addresses]="addresses" #addressModal (resetAddress)="resetAddress()" (resultForm)="addAddressAfterCreate($event)"></address-modal>
<app-confirmation-modal
  #confirmModal
  [message]="'Bạn có chắc muốn xóa địa chỉ này khỏi danh sách?'"
  (confirmed)="confirmDelete($event)"
>
</app-confirmation-modal>
