import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { CommonService } from '@/app/shared/services/common.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, afterNextRender } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent {

  public breadcrumb: Breadcrumb = {
    title: "Dashboard",
    items: [
      { label: 'Account', active: true },
    ]
  };

  public open: boolean = false;
  private routerEvent: Subscription;
  isAccountActivated: boolean = false;
  constructor(
    public commonService: CommonService,
    private router: Router,
    private accountService: AccountService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    };
  }

  ngOnInit() {
    this.commonService.getOpenMobileMenu.subscribe({
      next: (val: any) => {
        if (val) {
          this.open = val;
        }
      }
    });

    //this.isAccountActivated = this.accountService.checkAccountActivated();
    this.routerEvent = this.router.events.subscribe({
      next: (val: any) => {
        if (val instanceof NavigationEnd) {
          if (this.router.url.includes("/account/")) {
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo({top: 0, behavior: 'smooth'});
            };
          }
        }
      }
    });

  }

  setBreadcrumb(value: any) {
    this.breadcrumb.items.splice(1, 1);
    this.breadcrumb.items.map(i => i.active = false);
    this.breadcrumb.items.push({ label: value.name, active: true, icon: value.icon });
  }

  openMenu(value: any){
    this.open = value;
  }

  ngOnDestroy() {
    this.routerEvent?.unsubscribe();
  }

}
