import { SearchUserQueryString } from '@/app/shared/filter';
import { Cart, CartItem } from '@/app/shared/interface/cart.interface';
import { PaginationModel, Product, ProductModel } from '@/app/shared/interface/product.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { ProductService } from '@/app/shared/services/product.service';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { WishlistService } from '@/app/shared/services/wishlist.service';
import { Component, TransferState, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { AccountService } from '@/app/shared/services/account.service';
import { Router } from '@angular/router';
import { EventEmitter, Inject, Input, Output, afterNextRender } from '@angular/core';
import { AuthService, TOKEN } from '@/app/shared/services/auth.service';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrl: './wish-list.component.scss'
})
export class WishListComponent {

  @ViewChild("confirmModal", { static: false }) confirmModal: any;
  public isAccountActivated: boolean = false;
  public cart: Cart;
  public cartItem: CartItem;
  selectedItem: Product;

  wishlist: Product[] = [];
  wishlistIds: number[] = [];
  public inputText: string;
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)
  private searchSubject = new Subject<string>();
  private changeQueryStringSubcription?: Subscription;
  public searchUserQuery: SearchUserQueryString = new SearchUserQueryString();
  @Output() productLabel: EventEmitter<any> = new EventEmitter<any>();

  public skeletonLoader: boolean = false;
  public skeletonItems = Array.from({ length: 40 }, (_, index) => index);

  constructor(
    private queryStringService: QueryStringService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private productService: ProductService,
    private wishlistService: WishlistService,
    private toastService: ToastrService,
    private router: Router,
    private authService: AuthService,
    private transferState: TransferState
  ) {
  }

  ngOnInit() {
    this.isAccountActivated = this.authService.isAccountActivated();
    this.changeQueryStringSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
      this.searchUserQuery = this.queryStringService.getQueryString(new SearchUserQueryString());
    });

    this.route.queryParams.subscribe(params => {
      if (params["s"]) {
        this.inputText = params["s"];
        this.search(this.inputText);
      }
      else {
        this.commonService.getWishlist.subscribe({
          next: (res: number[]) => {
            if (res && res.length > 0) {
              this.wishlistIds = res;
              this.getWishList();
            }
          }
        });
      }
    });

    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.searchUserQuery.value = this.inputText;
      this.queryStringService.changeQueryString(this.searchUserQuery);
    });

    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
        }
      }
    })
  }

  getWishList() {
    this.search("");
  }

  search(value: string) {
    let body: any;
    let request = this.queryStringService.buildFetchingModel();

    if (value) {
      body = {
        limit: request.limit,
        offset: request.offset,
        searchTerm: value,
        maxNumberOfResult: 20
      }
    }
    else {
      body = {
        limit: request.limit,
        offset: request.offset,
        productIds: this.wishlistIds
      }
    }

    const token = this.transferState.get(TOKEN, '');
    
    if(token) {
      this.productService.getProductsForMember(JSON.stringify(body)).subscribe({
        next: (res: ProductModel) => {
          if (res.products) {
            this.wishlist = res.products;
  
            this.wishlist.forEach((product: Product) => {
              product.cartItem = this.getCartItem(product);
            });
  
            let pagination: PaginationModel = {
              limit: body.limit,
              offset: body.offset,
              searchTerm: body.searchTerm,
              totalItems: res.total,
            };
            setTimeout(() => {
              this.queryStringService.changePagination(pagination);  
            }, 10);
            
          }
        }
      });
    }
    else{
      this.productService.getProductsForGuest(JSON.stringify(body)).subscribe({
        next: (res: ProductModel) => {
          if (res.products) {
            this.wishlist = res.products;
  
            this.wishlist.forEach((product: Product) => {
              product.cartItem = this.getCartItem(product);
            });
  
            let pagination: PaginationModel = {
              limit: body.limit,
              offset: body.offset,
              searchTerm: body.searchTerm,
              totalItems: res.total,
            };
            setTimeout(() => {
              this.queryStringService.changePagination(pagination);  
            }, 10);
            
          }
        }
      });
    }
  }

  input() {
    if (this.inputText) {
      this.searchSubject.next(this.inputText);
    }
    else if (this.inputText != undefined) {
      this.searchSubject.next(this.inputText);
      this.getWishList();
    }
  }

  getCartItem(product: any) {
    this.cartItem = {
      imgUrl: product.imageUrls[0],
      name: product.name,
      price: product.basePrice,
      basePrice: product.basePrice,
      productID: product.productID,
      quantity: 0,
      labels: product.labels,
      SKU: product.SKU,
      slug: product.slug
    }

    if (this.cart && this.cart.items.length > 0) {
      for (let item of this.cart.items) {
        if (item.productID == product.productID) {
          this.cartItem = item;
          break;
        }
      }
    }

    return this.cartItem;
  }

  openModal(product: Product) {
    this.selectedItem = product;
    this.confirmModal.openModal();
  }

  confirmDelete(value?: boolean) {
    if (value) {
      const body = {
        productId: this.selectedItem.productID
      }
      this.wishlistService.addWishlist(JSON.stringify(body)).subscribe({
        next: (res: number[]) => {
          if (res) {
            this.toastService.success('Loại bỏ khỏi danh sách yêu thích!', "", {positionClass: "toast-top-right"});
            if (res.length == 0) {
              this.wishlist = [];
            }
            this.commonService.setWishlist(res);
          }
        },
        error: (error: any) => {
          this.toastService.error('Không thể cập nhật danh sách yêu thích!', "", {positionClass: "toast-top-right"});
          console.log(error);
        }
      })
    }
  }

  queryStringBuilder(slug: string) {
    this.productLabel.emit(slug);
    // set label query string
    this.router.navigate(['/product'], {
      queryParams: {
        label: slug
      },
      queryParamsHandling: '' // optional
    });
  }
}
