<div class="col-xxl-12 col-lg-12 col-md-12 col-sm-12 d-grid gap-4 pt-4">
  <div class="d-grid gap-4">
    <h3 class="fw-bold">DANH SÁCH MÃ GIẢM GIÁ CỦA BẠN</h3>
    <ng-container *ngIf="skeletonLoader; else productContent">
      <div class="d-flex gap-4">
        <app-skeleton-product-box class="w-25" *ngFor="let item of skeletonItems"
          [style]="'vertical'"></app-skeleton-product-box>
      </div>
    </ng-container>
    <ng-template #productContent>
      <div class="row">
        <div *ngFor="let voucher of vouchers; let i = index" class="card-voucher col-md-6 col-sm-6 mb-4">
          <div class="d-flex justify-content-between border-dotted ps-3 pt-2 pe-3 gap-3">
            <div class="d-flex flex-column justify-content-evenly">
              <h3 class="color-red">
                Giảm
                <span *ngIf="voucher.discountType == 1; else percent">{{ voucher.discountValue | currencySymbol }}</span>
                <ng-template #percent>
                  {{ voucher.discountValue }} %
                </ng-template>
              </h3>
              <h6>
                Giảm giá
                <span *ngIf="voucher.discountType == 0"> tối đa&nbsp; </span>
                <span class="fw-bold" *ngIf="voucher.discountType == 0">
                  {{ voucher.maximumAmount | currencySymbol }}
                </span>
                cho đơn hàng từ
                <span class="fw-bold">
                  {{ voucher.minimumSpentRequired | currencySymbol }}
                </span>
              </h6>
              <h6>
                Số lượng mã giảm giá:
                <span class="text-danger fw-bold">{{ voucher.remainingUses }}</span>
              </h6>
              <h6>
                Số lượt sử dụng/ngày:
                <span class="text-danger fw-bold">{{ voucher.usesLimitPerDay > 9999 ? "Không giới hạn" : voucher.usesLimitPerDay }}</span>
              </h6>
            </div>
            <div class="d-grid gap-1">
              <div class="position-relative justify-content-center d-flex align-items-center">
                <span class="position-absolute text-white">
                  x{{ voucher.remainingUses }}
                </span>
                <img class="m-auto" src="assets/svg/itemvoucher.svg" />
              </div>

              <span class="d-flex gap-1" style="background-color: #FFF6EF;" (click)="popupDetail(voucher)">
                <h4 class="btn btn-sm voucherSVG" style="
                  color: #e88438;
                  border: 1px dotted #e88438;
                  border-radius: 10px;
                ">
                  <img class="me-1" src="assets/svg/question 1.svg" />
                  <span> Nhấn xem điều kiện</span>
                </h4>
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center p-3">
            <h6>HSD: {{ voucher.conditions?.end | date : "dd/MM/yyyy" }}</h6>
            <button class="voucher-btn d-flex gap-1 align-items-center" (click)="copyToClipboard(voucher)">
              {{ voucher.code }}
              <img src="assets/svg/copy 3.svg" />
            </button>
          </div>
        </div>
        <ng-template #noVouchers>
          <div class="p-3 bg-white rounded-3">
            <app-no-data [class]="'no-data-added position-relative'" [description]="'Bạn chưa có mã giảm giá nào'">
            </app-no-data>
          </div>
        </ng-template>
      </div>
    </ng-template>
  <!-- <div class="d-grid gap-4 d-none">
    <h3 class="fw-bold">LỊCH SỬ DÙNG MÃ GIẢM GIÁ</h3>
    <div class="p-4 bg-white rounded-3 mobile-pd">
      <div class="d-flex justify-content-end align-items-center gap-3">
        <h6>Từ ngày</h6>
        <form class="row row-cols-sm-auto">
          <div class="col-12">
            <div class="input-group">
              <input class="form-control date-input" name="fromDate" placeholder="ngày/tháng/năm" autocomplete="off"
                [(ngModel)]="fromDate" ngbDatepicker #from="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3 date-btn" (click)="from.toggle()" type="button">
                <img src="assets/svg/calendar 1.svg" />
              </button>
            </div>
          </div>
        </form>
        <h6>Đến ngày</h6>
        <form class="row row-cols-sm-auto">
          <div class="col-12">
            <div class="input-group">
              <input class="form-control date-input" name="toDate" placeholder="ngày/tháng/năm" autocomplete="off"
                [(ngModel)]="toDate" ngbDatepicker #to="ngbDatepicker" />
              <button class="btn btn-outline-secondary bi bi-calendar3 date-btn" (click)="to.toggle()" type="button">
                <img src="assets/svg/calendar 1.svg" />
              </button>
            </div>
          </div>
        </form>
        <button class="voucher-btn ps-3 pe-3 d-flex gap-1 align-items-center" (click)="search()">
          Tìm kiếm
        </button>
      </div>
      <ng-container>
        <div class="mt-0 p-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th *ngFor="let col of headerColumns">{{ col }}</th>
                </tr>
              </thead>
              <tbody *ngIf="voucherHistories.length > 0">
                <ng-container>
                  <tr *ngFor="let voucher of voucherHistories; let i = index">
                    <td>{{ voucher.usedDate | date : "dd/MM/yyyy" }}</td>
                    <td>{{ voucher.code }}</td>
                    <td style="width: 35%">
                      Cho đơn hàng từ
                      {{ voucher.minimumSpentRequired | currencySymbol }}
                    </td>
                    <td>
                      <button class="voucher-detail-btn" (click)="popupDetail(voucher)">
                        {{ voucher.orderId }}
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
    <app-no-data class="position-relative" *ngIf="voucherHistories.length == 0" [class]="'no-data-added'"
      [description]="'Bạn chưa có mã giảm giá nào'">
    </app-no-data>
  </div>
</div> -->
  <app-voucher-detail-modal #detailModal [selectedVoucher]="selectedVoucher"></app-voucher-detail-modal>
  <app-no-data [class]="'no-data-added'" [image]="'assets/svg/empty-items.svg'"
  [text]="'Chưa có voucher nào dành cho bạn!'" *ngIf="!vouchers.length">
</app-no-data>