import { DiscountType, Voucher } from '@/app/shared/interface/voucher.interface';
import { VoucherService } from '@/app/shared/services/voucher.service';
import { VoucherDetailModalComponent } from '@/app/shared/widgets/modal/voucher-modal/voucher-detail-modal/voucher-detail-modal.component';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrl: './voucher.component.scss'
})
export class VoucherComponent {

  @ViewChild("detailModal", { static: false }) detailModal: VoucherDetailModalComponent;
  
  vouchers: Voucher[] = [];
  voucherHistories: Voucher[] = [];
  selectedVoucher: Voucher;

  public skeletonLoader: boolean = true;
  public skeletonItems = Array.from({ length: 4 }, (_, index) => index);

  fromDate: string;
  toDate: string;

  public modalOpen: boolean = false;

  headerColumns = [
    "Ngày Sử Dụng",
    "Mã giảm giá",
    "Thông tin",
    "Đơn hàng liên quan"
  ];

  constructor(
    private voucherService: VoucherService,
    private toastService: ToastrService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.getVouchers();
    //this.getVoucherHistories();
  }

  getVouchers() {
    this.voucherService.getAvailableVouchers().subscribe({
      next: (res: Voucher[]) => {
        this.vouchers = res;
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        this.skeletonLoader = false;
      }
    })
  }

  getVoucherHistories() {
    this.voucherService.getVoucherHistories().subscribe({
      next: (res: Voucher[]) => {
        this.voucherHistories = res;
      },
      error: (error: any) => {
        console.log(error);
      },
    })
  }

  copyToClipboard(voucher: Voucher) {
    if(isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(voucher.code);
    }
    this.toastService.success("Sao chép mã giảm giá thành công!", "" , {positionClass: "toast-top-right"});
  }

  search() {
    // this.getVoucherHistories();
  }

  async popupDetail(voucher: any) {
    this.selectedVoucher = voucher;
    this.detailModal.openModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
