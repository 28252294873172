import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { CommonService } from '@/app/shared/services/common.service';
import { Component, TransferState } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// import $ from 'jquery';
declare var $: any;
import { FileService } from '@/app/shared/services/file.service';
import { FileType, FileTypeValue } from '@/app/shared/interface/file.interface';
import { firstValueFrom } from 'rxjs';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AuthService, TOKEN } from '@/app/shared/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  breadcrumb: Breadcrumb;
  public avatar: File[] = [];
  user: User;

  public status: string;

  public loaderStatus: boolean = false;

  form: FormGroup;

  days: Date[] = [];
  months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  years: number[] = [];

  date = new Date();

  day: number = this.date.getDate();
  month: number = this.date.getMonth() + 1;
  year: number = this.date.getFullYear();
  public fileType: string = FileTypeValue.UserAvatar;
  public avatarUrl: string = '';

  public allowedTypes = ['image/png', 'image/jpeg'];

  public fileSizeLimit = 1;

  constructor(
    private accountService: AccountService,
    private toastService: ToastrService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private cookieService: SsrCookieService,
    private authService: AuthService,
    private transferState: TransferState
  ) {
    this.form = this.formBuilder.group({
      fullName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl(''),
    });
  }

  ngOnInit() {
    this.years = this.setupYear();
    this.days = this.commonService.getDaysInMonthUTC(
      this.date.getMonth(),
      this.date.getFullYear()
    );
    this.loaderStatus = true;
    this.accountService.getUserData.subscribe({
      next: (res: User) => {
        if (res.id) {
          this.user = res;
          const d = new Date(this.user.doB);
          this.month = d.getMonth() + 1;
          this.year = d.getFullYear();
          this.day = d.getDate();

          const statusMapping: { [key: number]: string } = {
            0: 'None',
            1: 'Đang Hoạt Động',// tier 3
            2: 'Không Hoạt Động',
            3: 'Chờ Xét Duyệt',
            4: 'Đang Hoạt Động',// tier 1
            5: 'Đang Hoạt Động',// tier 2
          };
          this.status = statusMapping[this.user.status] || 'None';

          this.form.patchValue({
            fullName: this.user.fullName,
            phone: this.user.phone,
            email: this.user.email,
          });

          if (this.user.avatarUrl && this.user.avatarUrl.length > 0) {
            this.avatarUrl = this.user.avatarUrl;
          }

          setTimeout(() => {
            $('#popover')
              .on('mouseenter', () => {
                $('.popover').removeClass('d-none');
              })
              .on('mouseleave', () => {
                $('.popover').addClass('d-none');
              });
          }, 10);
          this.loaderStatus = false;
        }
      },
    });
  }

  setupYear(startYear?: number, endYear?: number) {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 1900;
    while (startYear <= currentYear) {
      years.push(currentYear--);
    }
    return years;
  }

  onSelect(event: any) {
    if (this.checkFileType(event)) {
      this.avatar = event.addedFiles;
      this.loaderStatus = true;
      if (event.addedFiles) {
        this.fileService
          .convertFileToBase64(event.addedFiles[0])
          .then((res: any) => {
            this.avatarUrl = res;
            this.user.avatarUrl = this.avatarUrl;
            this.accountService.setUserData(this.user);
          });
      }
    }
  }

  checkFileType(event: any) {
    const file = event.addedFiles[0];
    if (!this.allowedTypes.includes(file.type)) {
      this.toastService.error('Chỉ được up file có định dạng png, jpeg.', '', {
        positionClass: 'toast-top-right',
      });
      return false;
    }
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > this.fileSizeLimit) {
      this.toastService.error('File không được quá 1MB', '', {
        positionClass: 'toast-top-right',
      });
      return false;
    }
    return true;
  }

  onRemove(event: any) {
    this.avatar = [];
  }

  async save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    if (this.form.get('phone')!.value != this.user.phone) {
      this.toastService.warning(
        'Bạn không thể thay đổi số điện thoại đã đăng ký!',
        '',
        { positionClass: 'toast-top-right' }
      );
      return;
    }
    this.user.doB = new Date(Date.UTC(this.year, this.month - 1, this.day));
    this.user.fullName = this.form.get('fullName')?.value;
    this.user.email = this.form.get('email')?.value;
    // const token = this.cookieService.get('token');
    const token = this.transferState.get(TOKEN, '');

    
    let jwtToken = this.commonService.parseJwt(token!);

    if (this.avatar.length > 0) {
      var res = (await firstValueFrom(
        this.fileService.uploadFileUser(
          this.avatar,
          this.fileType,
          jwtToken.UserName,
          this.user.userName + '_avatar'
        )
      )) as any;

      if (res) {
        this.user.avatarUrl = res.fileUrl;
      }
    }
    this.loaderStatus = true;
    this.accountService.updateUser(JSON.stringify(this.user)).subscribe({
      next: (res: boolean) => {
        if (res) {
          this.authService.setCookie('fullname', this.user.fullName);
          // localStorage.setItem('fullname', this.user.fullName!);
          this.toastService.success(
            'Cập nhật thông tin cá nhân thành công!',
            '',
            { positionClass: 'toast-top-right' }
          );
          this.user.avatarUrl = this.avatarUrl;
          this.accountService.setUserData(this.user);
          this.loaderStatus = false;
        } else {
          this.toastService.warning(
            'Cập nhật thông tin cá nhân thất bại!',
            '',
            { positionClass: 'toast-top-right' }
          );
        }
      },
      error: (error: any) => {
        this.toastService.warning('Lỗi cập nhật thông tin cá nhân!', '', {
          positionClass: 'toast-top-right',
        });
      },
    });
  }

  selectGender(value: number) {
    this.user.gender = value;
  }

  changeDay(event: any) {
    this.day = new Date(event.target.value).getDate();
  }

  changeMonth(event: any) {
    this.month = Number(event.target.value);
    this.days = this.commonService.getDaysInMonthUTC(this.month - 1, this.year);
  }

  changeYear(event: any) {
    this.year = Number(event.target.value);
    this.days = this.commonService.getDaysInMonthUTC(this.month - 1, this.year);
  }
}
