import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { User } from '@/app/shared/interface/user.interface';
import { AccountService } from '@/app/shared/services/account.service';
import { CommonService } from '@/app/shared/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService, IS_AUTHENTICATED, TOKEN, USERNAME } from '@/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { Component, TransferState } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  breadcrumb: Breadcrumb;
  user: User;
  accountStatus: string = 'Đã duyệt';
  isAuthenticated: boolean = false;
  public form: FormGroup;
  public body: {
    UserName: string;
    CurrentPassword: string;
    NewPassword: string;
    ReNewPassword: string;
  };

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toastService: ToastrService,
    private authService: AuthService,
    private cookieService: SsrCookieService,
    private router: Router,
    private transferState: TransferState
  ) {
    this.form = this.formBuilder.group({

      password: new FormControl('', [
        Validators.required,
        //Validators.minLength(6),
      ]),
      newpassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      renewpassword: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  ngOnInit() {}

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      // create body
      // const token = this.cookieService.get('token');
      const token = this.transferState.get(TOKEN, '');

      // const userName = this.cookieService.get('username');

      const userName = this.transferState.get(USERNAME, '');

      let jwtToken = this.commonService.parseJwt(
        token!
      );
      this.isAuthenticated = this.authService.isAuthenticated();
      // this.isAuthenticated =
      //   jwtToken && jwtToken.UserName == userName!
      //     ? true
      //     : false;
      if (this.form.value.newpassword != this.form.value.renewpassword) {
        this.toastService.error(
          'Mật khẩu mới không khớp, vui lòng nhập lại',
          '',
          { positionClass: 'toast-top-right' }
        );
        return;
      }
      if (this.isAuthenticated) {
        this.body = {
          UserName: userName!,
          CurrentPassword: this.form.value.password,
          NewPassword: this.form.value.newpassword,
          ReNewPassword: this.form.value.renewpassword,
        };
        this.accountService
          .changepasswordUser(JSON.stringify(this.body))
          .subscribe({
            next: (res: any) => {
              if (res) {
                this.toastService.success('Đã cập nhật mật khẩu!', '', {
                  positionClass: 'toast-top-right',
                });
              } else {
                this.toastService.error(
                  'Mật khẩu hiện tại không đúng, vui lòng nhập lại!',
                  '',
                  { positionClass: 'toast-top-right' }
                );
              }
            },
            error: (error) => {
              this.toastService.success(
                'Cập nhật thất bại, vui lòng thử lại!',
                '',
                { positionClass: 'toast-top-right' }
              );
            },
          });
      }
    }
  }
}
